<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
            :downloadlink="headercontent.downloadlink"
          />
        </v-col>
        <v-col md="12" v-if="loading">
          <v-skeleton-loader
            class="mx-auto"
            type="table"
            :loading="loading"
            rows="5"
          ></v-skeleton-loader>
        </v-col>
        <v-col md="12" v-if="!loading">
          <v-simple-table class="table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">S/N</th>
                  <th class="text-left">Name</th>
                  <th class="text-left">Image</th>
                  <th class="text-right" colspan="2">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in allPosts.results.posts"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.title }}</td>
                  <td>
                    <img
                      :src="`https://app.necojobs.com.np${item.imageUrl}`"
                      alt=""
                      class="imagebox"
                    />
                  </td>
                  <td class="text-right">
                    <router-link :to="`/dashboard/post/add/Ads/${item.slug}`"
                      ><v-icon class="icons-action">
                        mdi-pencil
                      </v-icon></router-link
                    >
                    <v-icon
                      class="icons-action"
                      @click="() => deleteAdsAction(item._id)"
                    >
                      mdi-delete
                    </v-icon>
                    <!--
                    <v-icon class="icons-action"> mdi-download </v-icon>
                     -->
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <ul class="pagination-button" v-if="false">
            <li>Page : {{ (2 - 1) * 10 }} - 100</li>
            <li>
              <div>
                <v-btn @click="prevPage(0)" class="btn-dark">Prev</v-btn>

                <v-btn @click="nextPage(2)" class="btn-dark">Next</v-btn>
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Ads",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Ads Listing",
      addlink: "/dashboard/post/add/Ads",
      downloadlink: false,
    },
    title: "",
    adsposition: "",
    valid: true,
    loading: true,
  }),
  methods: {
    ...mapActions(["fetchPost", "deleteAds"]),
    validate() {},
    nextPage(data) {
      console.log(data);
    },
    prevPage(data) {
      console.log(data);
    },
    async deleteAdsAction(id) {
      if (confirm("Are you sure you want to delete this ads?")) {
        await this.deleteAds(id);
        this.fetchPost("ads");
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapGetters(["allPosts"]),
  },
  async created() {
    await this.fetchPost("ads");
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.form-section {
  padding: 15px 0;
  .col {
    padding: 0 10px;
  }
}
.filtertitle {
  margin-bottom: 10px;
}
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}

.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.pagination-button {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  .btn-dark {
    margin-top: 0;
    margin-left: 10px;
  }
}
.table-heading,
.pagination-button {
  border: 1px solid $menucolor;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
}
.icons-action {
  margin: 10px;
  font-size: $iconfont;
}

.imagebox {
  width: 150px;
}
</style>
